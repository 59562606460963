import { rundomAnim } from "../../@utils/utils-globals"
import { OwnIcon } from "../icons/module-icons"

export const BoxLoader = ({className, arrLength = 1, height, ...props}) => {
  if(!props.isShow) return null
  const arr = Array(arrLength).fill(0).map((e,i)=>i+1);
  const style = height  ?{ height } : {}
  return(
    <>
      { arr.map((_, i) => (
        <div className={`box-loader ${className}`} style = {style} key={i}>
          <div className={`load-bar ${rundomAnim()}`} ></div>
        </div>
      ))}
    </>
  )
}