import React from 'react';
import { RegexManager } from '../../../@utils/utils-regex-manager';
import { OwnIcon } from '../../icons/module-icons';
import { ButtonActionsCollection } from '../buttons/buttons-actions-collection';

export const InputBoxPropsDefault  = {
  onChange: ( callback ) => void(0),
  className: '',
  isError: false,
  placeholder: 'Please type something...',
  style: {},
  isMultiline: false,
  title: 'HERE EXAMPLE TITLE',
  errorText: `You can't leave this field empty!`,
  maxLength: undefined,
  minLength: 0,
  rows: 3,
  value: '',
  matchValue: '',
  useUnit: false,
  type: "null"  // username | youtube | password | null | repassword | email | name | none | color,
}

const INPUT_TYPE_ENUM = {
  username: 'email',
  youtube: 'text',
  password: 'password',
  null: 'text',
  repassword: 'password',
  number: 'number',
  email: 'email',
  name: 'text',
  none: 'text'
}


export class InputBox extends React.Component {
  constructor( props ){
    super(props);
    this.state = {
      value: props.value,
      isError: props.isError,
      type: INPUT_TYPE_ENUM[props.type] || "text",
    }
  }
  RegTest = new RegexManager();
  static defaultProps = InputBoxPropsDefault;

  componentDidUpdate = (prevProps) => {
    const { props } = this;
    if(prevProps.value !== props.value){
      this.setState({value: props.value })
    }
    if(prevProps.isError !== props.isError){
      this.setState({ isError: props.isError})
    }
  }

  onChange = (event) => {
    const { props } = this;
    let isError =  this.RegTest[ props.type ] ? this.RegTest[ props.type ]( event.target.value , props.minLength, props.matchValue) : false;
    const value =  event.target.value;
    // if(props.matchValue != value && props.type == 'repassword'){
    //   isError = true
    // }
    this.setState({ isError, value }, () => {
      this.props.onChange({isError, value});
    })
  }

  onClickEyeBtn = (event) => {
    const { state } = this;
    this.setState({type: state.type != 'password' ? INPUT_TYPE_ENUM[this.props.type] : "text"})
  }

  getStyleHidden = (props) => {
    if(props.type =='hidden'){
      return {
        display: 'none',
        height: 0,
        width: 0,
      }
    }
    return {}
  }
  render(){
    const { props, state } = this;
    const styleHidden = this.getStyleHidden(props);
    return (
      <div className = {"lazyfox_input_box"} style={styleHidden}>
          <label className = {`value_title ${state.isError ? 'error_text': ''}`}>{ props.title }</label>
        <div className = {`lazyfox_input_box_value ${state.isError ? 'error_box' : ''}`} >
          <div className = {'value_box'} style = {( props.type === 'password' || props.type === 'repassword') ? {paddingRight: '45px'} : {}}>
            { !props.isMultiline &&
              <input maxLength = { props.maxLength} 
              {...props} 
              minLength = { props.minLength } 
              className = {"input_box_values"}
              onChange = { this.onChange } 
              value = {  state.value }
              type = {state.type}
              placeholder = { props.placeholder }/>
            }
            { props.isMultiline &&
              <textarea  maxLength = { props.maxLength}  
                {...props} 
                minLength = { props.minLength } 
                className = {"input_box_values"} 
                placeholder = { props.placeholder } 
                onChange = { this.onChange } 
                value = { state.value }
                rows = { props.rows }>{ state.value }</textarea>
            }
            {( props.type === 'password' || props.type === 'repassword') &&
              <ButtonActionsCollection onClick = {this.onClickEyeBtn} className={"btn_open_pasword"} render = {() => (
                <>
                  <OwnIcon type = {"icon-eye-open"} isShow = {state.type == 'password'} className={"btn_open_pasword_icon"} />
                  <OwnIcon type = {"icon-eye-closed"} isShow = {state.type != 'password'} className={"btn_open_pasword_icon"} />
                </>
              )}/>
            }
          </div>
        </div>
        { state.isError &&
          <div className={"error_wrappers"}>
            <OwnIcon type = {"icon-alert"} className={"icons_error"} />
            <label className = {"error_text"}>{ props.errorText }</label>
          </div>
        }
      </div>
    )
  }
}
