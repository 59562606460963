import * as types from "../reducers-constant";

const initialState = {
  language: "ind",
};

export const Settings = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTINGS_LANGUAGE: {
      return {
        ...state,
        language: action.data,
      };
    }
    default:
      return state;
  }
};
