const REACT_ATTR = {
  "fill-rule": "fillRule",
  "stroke-linecap": "strokeLinecap",
  "stroke-width": "strokeWidth",
  "clip-rule": "clipRule",
  "stroke-linejoin": "strokeLinejoin",
  "class": "className",
  "view-box": "viewBox",
  "frameborder": "frameBorder",
  "referrerpolicy": "referrerPolicy",
  // "aria-hidden": "ariaHidden",
  // "aria-label": "ariaLabel",
  // "aria-disabled": "ariaDisabled",
  // "aria-live": "ariaLive",
  // "aria-atomic": "ariaAtomic",
  // "aria-modal": "ariaModal",
  "tabindex": "tabIndex",
  "allowfullscreen": "allowFullScreen",
  accept: 'accept',
  ['accept-charset']: 'acceptCharset',
  accesskey: 'accessKey',
  action: 'action',
  alt: 'alt',
  async: 'async',
  autocomplete: 'autoComplete',
  autofocus: 'autoFocus',
  autoplay: 'autoPlay',
  charset: 'charSet',
  checked: 'checked',
  class: 'className',
  cols: 'cols',
  colspan: 'colSpan',
  content: 'content',
  contenteditable: 'contentEditable',
  controls: 'controls',
  coords: 'coords',
  data: 'data',
  datetime: 'dateTime',
  defer: 'defer',
  dir: 'dir',
  disabled: 'disabled',
  download: 'download',
  draggable: 'draggable',
  enctype: 'encType',
  for: 'htmlFor',
  form: 'form',
  formaction: 'formAction',
  headers: 'headers',
  height: 'height',
  hidden: 'hidden',
  high: 'high',
  href: 'href',
  hreflang: 'hrefLang',
  ['http-equiv']: 'httpEquiv',
  id: 'id',
  label: 'label',
  lang: 'lang',
  list: 'list',
  loop: 'loop',
  low: 'low',
  max: 'max',
  maxlength: 'maxLength',
  media: 'media',
  method: 'method',
  min: 'min',
  multiple: 'multiple',
  muted: 'muted',
  name: 'name',
  novalidate: 'noValidate',
  open: 'open',
  optimum: 'optimum',
  pattern: 'pattern',
  placeholder: 'placeholder',
  poster: 'poster',
  preload: 'preload',
  readonly: 'readOnly',
  rel: 'rel',
  required: 'required',
  rows: 'rows',
  rowspan: 'rowSpan',
  sandbox: 'sandbox',
  scope: 'scope',
  selected: 'selected',
  shape: 'shape',
  size: 'size',
  sizes: 'sizes',
  span: 'span',
  spellcheck: 'spellCheck',
  src: 'src',
  srcdoc: 'srcDoc',
  srcset: 'srcSet',
  start: 'start',
  step: 'step',
  style: 'style',
  tabindex: 'tabIndex',
  target: 'target',
  title: 'title',
  type: 'type',
  usemap: 'useMap',
  value: 'value',
  width: 'width',
  wrap: 'wrap',
  // backgroundimage: "backgroundImage"
};

const STYLE_TYPE_CHECK_IMAGE = [
  "backgroundImage",
  "maskImage", 
  "webkitMaskImage"
]
const STYLE_TYPE_CHECK = [
  "width", "height", "position", "fontSize", "backgroundColor","border", "color"
// , "maskImage", "webkitMaskImage"
]

// const SKIPED_ATTR = {
//   "loop": true,
//   // "loading": true,
//   "sizes": true
// }

// const WHITE_LIST_BOOLEAN = {
//   "allowFullScreen": true,
//   "loop": true
// }

export const GenerateAtribute = (props) => {
  const res = {};
  const media = {...props.media, ...props.config, "key-id":props.id};
  if(media["fox-onclick"]){
    media["href"] = "#"
  }
  // if(media["href"] && media=""){
  //   media["href"] = "#"
  // }
  for (let key in media) {
    let newKey = key
    if(REACT_ATTR[key]){
      newKey = REACT_ATTR[key]
    }
    if(media[key] ){
      let mediaValue = media[key];
      if(typeof mediaValue === 'object' && key !== 'style'){
        mediaValue = JSON.stringify(mediaValue);
      }
      res[newKey] = mediaValue
    }
  }
  
  // res.className = media.class;
  // res["fox-onclick"] = media["fox-onclick"]
  if(props.className){
    res["className"] = `${res.className } ${props.className}`
  }
  res["style"] = getStyle({...res.style, ...res})
  return res
}

const getStyle = (attr) => {
  const style = {}
  for (let i = 0; i < STYLE_TYPE_CHECK_IMAGE.length; i++) {
    const key = STYLE_TYPE_CHECK_IMAGE[i]
    if(attr[key]){
      style[key] = `url(${attr[key]})`;
      // delete attr[key]
    }
  }
  for (let i = 0; i < STYLE_TYPE_CHECK.length; i++) {
    const key = STYLE_TYPE_CHECK[i]
    if(attr[key]){
      style[key] = attr[key];
      // delete attr[key]
    }
  }
  return style
}