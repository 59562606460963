import React from 'react';
export const ButtonActionsGlobalPropDefault = {
  onClick: (event ) => void(0),
  isActive: false,
  className: '',
  disabled: false,
} 

export class ButtonActionsGlobal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isActive: props.isActive
    }
  }
  static defaultProps = ButtonActionsGlobalPropDefault;

  onClick = ( event ) => {
    if(this.props.disabled) return
    this.setState({
      isActive: !this.state.isActive
    },() => {
      if(this.props.onClick){
        this.props.onClick( event );
      }
    });
  }

  getActive = () => {
    let className = `${this.state.isActive?'anim_zoom_out':'anim_zoom_in'}`;
    if(this.props.disabled) return 'disabled'
    return className;
  }
  
  render(){
    const { props } = this;
    return (
      <section className={`button_icons btn_clickable ${this.getActive()} ${props.className} `} onClick={this.onClick}>
        { 
          props.render &&
            props.render({
              isActive: this.state.isActive
            }) 
        }
      </section>
    )
  }
}