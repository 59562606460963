import React from 'react';
import { OwnIconVector } from './vectors';

const OwnIconDefaultProp = {
  className: '',
  onClick: ( event) => null,
  style: {},
  isActive: false,
  isShow: true,
  type: 'IconDownload'
}

const ColorSettings = {
  fill: 'currentColor',
  stroke: 'none',
}

const getRenderObj = ( jsonFile) => ({
  ...jsonFile,
  ...ColorSettings,
})

const removeText = (string = "", isActive) => {
  let str =  string?.replace?.(/icon-/,'') || ""
  if(isActive){ str = str+"-active"}
  str = str.split("-").map( (word) => (word[0].toUpperCase() + word.slice(1)) ).join("");
  return str
} ;


export const OwnIcon = ( props = OwnIconDefaultProp ) => {
  props = { ...OwnIconDefaultProp, ...props};
  let svgObj = props.vector;
  if(!props.vector){
    const iconType = removeText(props.type, props.isActive);
    svgObj = OwnIconVector[iconType] || null;
  }
  const svgVector = getRenderObj(svgObj);
  if(!props.isShow) { return null }
  if(svgObj){
    return(
      <svg className={`own_icon ${props.type} ${props.className}`} onClick={props.onClick}  width="24" height="24" viewBox = { svgVector.viewBox } fill = {svgVector.fill} xmlns="http://www.w3.org/2000/svg">
        {svgVector?.path?.map((item, index) =>(
          <path  {...item} key = {index} ></path>
        ))}
        {svgVector.rect &&
          <rect {...svgVector.rect}></rect>
        }
        {svgVector.circle &&
          <circle {...svgVector.circle}></circle>
        }
        
      </svg>
    )
  }
  return null
}

