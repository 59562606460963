import { useCallback } from "react";
import  './index.scss'
import { OwnIcon } from "../../@component/icons/module-icons";
import { GenerateAtribute } from "../../@utils/generate-atribute";

export const IconEl = (props) => {
    const getElementConfig = (props) => {
      const res = GenerateAtribute(props);
      return res
    }

    return (
        <i {...getElementConfig(props)} className="fox_icon" ><OwnIcon className="icon_custom" type={props.media["fox-value"] || props.media["fox-data"] || "user"} {...getElementConfig(props)} /></i>
    );
};