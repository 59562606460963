import React from 'react';

export class ButtonRender extends React.Component{
  
  render(){
    const { props } = this;
    return (
      <div className={`${props.className} button-render`}>
        {props.children}
      </div>
    )
  }
}