import React, { Component } from "react";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

export class OddoMetter extends Component {
  constructor(props) {
    super(props);
  }
  elRef = React.createRef(null)
  elArray = {}
  itemHeight = {};
  static defaultProps = {};
  

  componentDidMount = () => {
    if(this.elRef){
      const value = this.props.media["fox-value"] || 99
      setTimeout(() => {
        this.createOdoMetter(value, this.elRef)
      },2000)
    }
  }
  
  _elementPos = {}
  createOdoMetter = (value, elTarget) => {
    // const style = document.createElement("style");
    // style.innerHTML = `.slidee .slider{margin-top: 0!important} `
    // document.head.append(style);
    const el = elTarget;
    el.innerHTML = '';
    const valArr = value?.toString()?.split("");
    const arr = [1,2,3,4,5,6,7,8,9,0];
    valArr.forEach(async (item, i) => {
      const counterWrapper = document.createElement("div");
      counterWrapper.style["overflow"] = "hidden";
      let elHeight = 0;
      const slider = document.createElement("div");
      slider.classList.add("slider")
      slider.classList.add(`number_${i}`);
      el.append(counterWrapper);
      counterWrapper.append(slider);
      
      await arr.forEach((targetVal) => {
        const itemCounter = document.createElement("div");
        itemCounter.innerHTML =targetVal ;
        slider.append(itemCounter)
        elHeight = itemCounter?.getBoundingClientRect?.()?.height;
      })
      const marginPos = i % 2 == 0 ? -1 : 1;
      slider.setAttribute("item-height", elHeight)
      if(i % 2 == 0){
        slider.style["marginTop"] = `${((arr.length - 1) * elHeight * marginPos)}px`;
        this._elementPos[i] = ((arr.length - 1) * elHeight * marginPos);
      }else{
        // slider.style["marginTop"] = `${((arr.length - 1) * elHeight * -1)}px`;
        // this._elementPos[i] = ((arr.length - 1) * elHeight * -1)
        slider.style["marginTop"] = `0px`;
        this._elementPos[i] = `0px`;

      }

      this.elArray[i]= slider
      this.itemHeight[i] = elHeight
      counterWrapper.style["height"] = `${elHeight}px`;
    })

    this.animatedScroll()
  }

  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  startAnimatedBack =() => {
    const value = this.props.media["fox-value"] || 99;
    const valArr = value?.toString()?.split("");
    valArr.forEach((_, i) => {
      // const className = `.slider.number_${i}`;
      // this.elRef.querySelector(className).style["marginTop"] = `${this._elementPos[i]}px`;
      this.elArray[i].style["marginTop"] = `${this._elementPos[i]}px`;
    })
  }

  startAnimated =() => {
    const value = this.props.media["fox-value"] || 99;
    const valArr = value?.toString()?.split("");
    valArr.forEach((val, i) => {
      // const marginPos = i % 2 == 0 ? -1 : 1;
      // const className = `.slider.number_${i}`;
      // const itemHeight = this.elRef.querySelector(className).getAttribute('item-height')
      // this.elRef.querySelector(className).style["marginTop"] = `${itemHeight * ((val - 1) * -1)}px`;
      this.elArray[i].style["marginTop"] = `${this.itemHeight[i] * ((val - 1) * -1)}px`;
    })
  }

  _timer = null;
  animatedScroll = () => {
    window.document.body.addEventListener("scroll", (e) => {
      const triggerButton = window.innerHeight / 5 * 4;
      // const targetAnimated = document.querySelectorAll('.animated');
      if(this.elRef?.getBoundingClientRect?.()?.top < triggerButton){
        clearTimeout(this._timer);
        setTimeout( this.startAnimated)
      }else{
        // clearTimeout(this._timer);
        // setTimeout( this.startAnimatedBack)
        // this.startAnimated()
      }
    })
  }
  

  render() {
    const { props, state } = this;
    return (
      <section className={`oddo-metter-parent ${props.className}`} {...this.getElementConfig(props)}>
        <section className={"oddo-metter"} ref = {(ref)=> this.elRef = ref}>
        
        </section>
      </section>
    );
  }
}
