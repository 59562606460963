export const LIST_OF_REGEX = {
  email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  number: /([0-9])+$/,
  name: /([a-zA-Z '])+$/,
  userName: /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/,
  spaceName: /([a-zA-Z])/,
  spaceNumber: /([0-9])/,
  spaceNull: /([a-zA-Z])/,
  password: /(?=.*[a-zA-Z])(?=.{8,})/g,
  null: /([^\s])/,
  money: /[0-9]/g,
  youtube: /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/,
  link: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  clears: (e) => {
    const string = e.replace(/\s+/, '');
    return string.replace(/\s+$/, '');
  },
};

export class RegexManager {
  
  name = (value, minLength) => {
    value = value !== null ? value : '';
    value = LIST_OF_REGEX.clears(value);
    if (LIST_OF_REGEX.name.test(value) && LIST_OF_REGEX.spaceName.test(value) && value.length >= minLength) {
      return false;
    }
    return true;
  };

  username = (value, minLength)  => {
    value = value !== null ? value : '';
    value = LIST_OF_REGEX.clears(value);
    if (LIST_OF_REGEX.userName.test(value) && LIST_OF_REGEX.spaceName.test(value) && value.length >= minLength) {
      return false;
    }
    return true;
  };

  number = (value, minLength)  => {
    value = value !== null ? value : '';
    value = LIST_OF_REGEX.clears(value);
    if (LIST_OF_REGEX.number.test(value) && LIST_OF_REGEX.spaceNumber.test(value) && value.length >= minLength) {
      return false;
    }
    return true;
  };

  email = (value, minLength) => {
    value = value !== null ? value : '';
    value = LIST_OF_REGEX.clears(value);
    if (LIST_OF_REGEX.email.test(value)) {
      return false;
    }
    return true;
  };

  password = (value, minLength) => {
    value = value !== null ? value : '';
    value = LIST_OF_REGEX.clears(value);
    if (value.length >= 8) {
      return false;
    }
    return true;
  };

  repassword = (value = '', target = '#%$#%$', minLength) => {
    value = LIST_OF_REGEX.clears(value);
    target = LIST_OF_REGEX.clears(target);
    if (target === value) {
      return false;
    }
    return true;
  };

  null = (value, minLength) => {
    value = value !== null ? value : '';
    if (LIST_OF_REGEX.null.test(value) && value.length >= minLength) {
      return false;
    }
    return true;
  };

  youtube = (value) => {
    value = value !== null ? value : '';
    value = LIST_OF_REGEX.clears(value);
    const parsed = value.match(LIST_OF_REGEX.youtube);
    if (parsed && parsed[2]) {
      return { result: false, value: parsed[2] };
    }
    return { result: true, value };
  };

  
  clears = (e) => {
    return LIST_OF_REGEX.clears(e);
  };
}