import React, { Component } from "react";
import './index.scss'
import { OwnIcon } from "../../@component/icons/module-icons";

export class PlayMusciButton extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  mainRef = React.createRef().current
  constructor(props) {
    super(props);
    this.state = {
      pos: 0
    }
  }
  componentDidUpdate = (preveProps) => {
    if(preveProps.isPlay != this.props.isPlay){
      this.onPlay()
    }
  }
  onPlay = () => {
    if(this.mainRef){
      const el = this.mainRef.querySelector(".MusicPlay");
      if(el?.className?.animVal?.match?.(/animated/g)){
        el.classList.remove("animated-song");
        this.doPlayMusic("pause")
      }else{
        el.classList.add("animated-song");
        this.doPlayMusic("play")
      }
    }
  }

  doPlayMusic = (status = "play") => {
    if(this.mainRef){
      if(this.mainRef.querySelector(".song")){
        if(status == "play"){
          this.mainRef.querySelector(".song").play()
          this.mainRef.querySelector(".song").loop = true;
        }else{
          this.mainRef.querySelector(".song").pause()
        }
      }
    }
  }
  
  render() {
    const { props, state } = this;
    return (
      <section ref={(ref) => this.mainRef = ref} className={`audio-box skip-check ${props.className}`} onClick={this.onPlay}>
        <audio className="song" loop="">
          <source src={props.song} type="audio/mp3"></source>
        </audio> 
        <div class="elementor-icon-wrapper">
          <OwnIcon type="MusicPlay" />
        </div> 
      </section>
    );
  }
}
