import React, { Component, createElement } from "react";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

const monthsName = ["Januari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
export class MonthDiskon extends Component {
  constructor(props) {
    super(props);
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    if(props.className){
      res["className"] = `html-element ${props.className}`
    }
    return res
  }

  getMonth = () => {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    const monthNumber = date.getMonth()
    
    return `( 15 ${monthsName[monthNumber] || ""} ${date.getFullYear()} )`
  }
  

  render() {
    const { props, state } = this;
    return createElement(
      props.type,
      { ...this.getElementConfig(props) },
      this.getMonth(),
      props.children,
    );
  }
}
