import React, { Component } from "react";
import { BrowserRouter, StaticRouter, RouteProps } from "react-router-dom";

import { RouterMap } from "./routers-map";
import { StoreReducersPackage } from "./reducers-map";

import storage from "redux-persist/lib/storage";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import {thunk} from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
export * from "./routers-map";
export * from "./actions-map";
export * from "./reducers-constant";

export const PropDefault = {
  renderFrom: "browser",
  dataStore: {},
  // dataRouteMap: [],
  whitelist: ["StoragesSystem"],
};

export class MainApps extends Component {
  static defaultProps = PropDefault;

  configureStore = (dataStore, whitelist) => {
    const initialState = {};
    const persistConfig = {
      key: "root",
      storage,
      whitelist: whitelist,
    };
    const persistedReducer = persistReducer(
      persistConfig,
      StoreReducersPackage(dataStore)
    );
    const store = createStore(
      persistedReducer,
      initialState,
      applyMiddleware(thunk)
    );
    //store.dispatch(actionCreators.GetLocalization());
    const persistedStore = persistStore(store);
    return {
      store,
      persistedStore,
    };
  };

  render() {
    const { props } = this;
    const { store, persistedStore } = this.configureStore(
      props.dataStore,
      props.whitelist
    );
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <BrowserRouter>
            <RouterMap renderRoute={props.dataRouteMap || []} />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}
