import React, { Component } from "react";
import { Routes, Route, RouteProps } from "react-router-dom";
const RouterMapPropDefault = {
  renderRoute: [],
};

export class RouterMap extends Component {
  static defaultProps = RouterMapPropDefault;
  render() {
    const { props } = this;
    return (
      <Routes>
        {props.renderRoute?.map((item, index) => (
          <Route key={`route_list_${index}`} {...item} />
        ))}
      </Routes>
    );
  }
}
