import React, { Component, createElement } from "react";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";


const STYLE_TYPE_CHECK = ["backgroundImage", "maskImage"]

export class Parameters extends Component {
  constructor(props) {
    super(props);
  }
  
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    if(props.className){
      res["className"] = `html-element ${props.className}`
    }
    return res
  }


  getStyle = (attr) => {
    const style = {}
    for (let i = 0; i < STYLE_TYPE_CHECK.length; i++) {
      const key = STYLE_TYPE_CHECK[i]
      if(attr[key]){
        style[key] = `url(${attr[key]})`;
      }
    }
    return style
  }

  componentDidMount = () => {
    if(this.props.className?.match(/triggerdone/)){
      this.props.onTriggerDone?.(this.props)
    }
  }

  jsEncodeURi= (str = "") => {
    if(str){
      const arr = str.split("-");
      return arr.join(" ")
    }
    return "nama tamu"
  }

  render() {
    const { props, state } = this;
    return createElement(
      props.type,
      { ...this.getElementConfig(props) },
      this.jsEncodeURi(props.searchParams?.get?.("tamu")),
      props.children,
    );
  }
}
