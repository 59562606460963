import React, { forwardRef } from 'react';
import { useLocation, useNavigate, useParams, useMatch, useSearchParams} from 'react-router-dom';


export const withRouter = (Component, conf = {isAuthPage:  false, isRestriected: false}) => {
    const routeForward = forwardRef((props, ref) => {
      const location= useLocation();
      const navigate= useNavigate();
      const params = useParams();
      const match =  useMatch(location.pathname);
      const [searchParams] = useSearchParams()
      
      return <Component ref={ ref } navigate = {navigate} searchParams = {searchParams} params = {params} location = { location } match = {match} { ...props } />;
    });

    return routeForward;
};
