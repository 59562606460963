import React, { Component } from "react";
import './index.scss'
import { OwnIcon } from "../../@component/icons/module-icons";

export class NavigationBar extends Component {
  static defaultProps = {

  };
  mainRef = React.createRef().current
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  onClick = (key) => {
    const docTarget = document.querySelector(`[fox-page="${key}"]`) ||  document.querySelector(`[fox-key="${key}"]`) ||  document.querySelector(`[fox-pages="${key}"]`) ;
    // const ellArr = document.querySelectorAll(".navbar_icon")
    // // ellArr.forEach((item) => {
    // //   item.classList.remove("active")
    // // })
    if(docTarget){
      docTarget.scrollIntoView({ behavior: "smooth", });
    }
  }
  
  render() {
    return (
      <section className={"desktop-bar add-shad navigation_bar skip-check"}  style={{display: "block"}}>
        <div className="wrapper_bar ">
          <ul className="padding-bar">
            <li>
              <a className="navbar_icon icon-date active" id="home" onClick={() => this.onClick("home")}>
                <OwnIcon type="home" />
              </a>
            </li>
            <li>
              <a className="navbar_icon icon-date" id="love" onClick={() => this.onClick("love")}>
                <OwnIcon type="heart" />
              </a>
            </li>
            <li>
              <a className="navbar_icon icon-date" id="date" onClick={() => this.onClick("date")}>
                <OwnIcon type="date" />
              </a>
            </li>
            <li>
              <a className="navbar_icon icon-date" id="gallery" onClick={() => this.onClick("gallery")}>
                <OwnIcon type="album" />
              </a>
            </li>
            <li>
              <a className="navbar_icon icon-date" id="comments" onClick={() => this.onClick("comments")}>
                <OwnIcon type="comment" />
              </a>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}
