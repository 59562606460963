import React from 'react';

export const ButtonActionsCollectionPropDefault = {
  onClick: ( event) => void(0),
  //render: ( callback: object ) => (<></>),
  isActive: false,
  className: '',
}

export class ButtonActionsCollection extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isActive: props.isActive
    }
  }
  static defaultProps = ButtonActionsCollectionPropDefault;

  onClick = ( event ) => {
    this.setState({
      isActive: !this.state.isActive
    },() => {
      // do call API
      if(this.props.onClick){
        this.props.onClick( event );
      }
    });
  }
  render(){
    const { props } = this;
    return (
      <div className={`button_icons ${props.className} ${this.state.isActive?'anim_zoom_out':'anim_zoom_in'}`} onClick={this.onClick}>
        { 
          props.render &&
            props.render({
              isActive: this.state.isActive
            }) 
        }
      </div>
    )
  }
}
