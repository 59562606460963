import React, { Component } from "react";
import { Helmet } from "react-helmet";

export class FontHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { props, state } = this;
    return (
     <>
      <Helmet>
        <style>{props.media?.text}</style>
        { (props.fonts || []).map((item,i) => (
          <link href={item.src} rel="stylesheet"></link>
        ))}
      </Helmet>
     </>
    );
  }
}
