import React, { Component } from "react";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

export class GallerySlider extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  mainRef = React.createRef().current
  constructor(props) {
    super(props);
    this.state = {
      pos: 0
    }
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  componentDidMount = () => {
    this.init()
  }

  _timerCount = null
  init = () => {
    if(this.mainRef){
      clearTimeout(this._timerCount)
      this._timerCount = setTimeout(this.setSlide, 5000)
    }
  }

  _counter = 0;
  setSlide = () => {
    this._counter += 1;
    if( this._counter >= (this.props.galery || []).length - 1){
      this._counter = 0
    }
    this.setState({pos: this._counter}, () => {
      this.init()
    });
  }

  render() {
    const { props, state } = this;
    const photos = props.galery || [];
    
    const style = {
      opacity: '1',
      transform: "scale(1.2)"
    }

    return (
      <section style={{width:'100%'}} ref={(ref) => this.mainRef = ref} {...this.getElementConfig(props)} className={`fox_galery_slider ${props.className} ddd`} >
        <div className="slider_wrapper">
          <div className="slider_inside">
            {photos.map((item,i) => (
              <img src={item.src} alt="" style = { i == state.pos ? style : {}} className={`slider_image${i}`} key={i} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}
