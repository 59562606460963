export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const getRandomInt = (max) =>{
  return Math.floor(Math.random() * max);
}

const userName = "Ridwan rudi budi adit huda hari supri";
const company = "Cevrolet Astra Indomie Rundom Hora Apa UKI";
const jabatan = "admin staff magang";
const str = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a purus quis dolor hendrerit bibendum nec ac diam. Nunc arcu dui, commodo in turpis et, consectetur facilisis massa. Sed ac neque ex. Pellentesque enim lorem, commodo a libero id, gravida sollicitudin eros. In dignissim nec lectus a placerat. Vestibulum porta augue porta dui convallis vulputate. Cras dui odio, congue id sodales consequat, vehicula non neque. Integer enim metus, imperdiet id nisi a, posuere pulvinar leo. Maecenas venenatis tortor ut velit varius suscipit. Vestibulum eu feugiat quam. Mauris sollicitudin venenatis pulvinar. Duis pulvinar, nisl eu pellentesque rhoncus, ipsum odio malesuada nibh, vel dignissim nisi diam non neque. Quisque in neque ullamcorper, ornare urna non, tincidunt metus. Integer venenatis justo sed pulvinar suscipit. Suspendisse mauris velit, ornare in quam non, luctus dictum lorem."
const rundomKey  = (key) => {
  const func = {
    name: () => {
      const arr = str.split(" ");
      const newArr = []
      for (let index = 0; index < 5; index++) {
        newArr.push(arr[getRandomInt(arr.length)])
      }
      return newArr.join(' ');
    },
    type: () => {
      const arr = ["pdf","doc","docx","xls", "xlsx", "txt"];
      return arr[getRandomInt(arr.length)];
    },
    date: () => {
      return `Senin, ${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    },
    user: () => {
      const arr = userName?.split(' ');
      const arrMagang = jabatan?.split(' ');
      return {
        name: arr[getRandomInt(arr.length)],
        typeStaff: arrMagang[getRandomInt(arrMagang.length)] 
      }
    },
    companyName: () => {
      const arr = company?.split(' ');
      return arr[getRandomInt(arr.length)]
    },
    typeStaff: () => {
      const arrMagang = jabatan?.split(' ');
      return arrMagang[getRandomInt(arrMagang.length)] 
    },
    id: () => {
      return uuidv4()
    },
    userName: () => {
      const arr = userName?.split(' ');
      return arr[getRandomInt(arr.length)]
    },
    status: () => "active",

  }
  return func[key]()
}

export const rundomData = (obj, length = 100) => {
  const arr = [];
  for (let i = 0; i < length; i++) {
    const item = {};
    for (const key in obj) {
      const value = obj[key];
      item[key] = rundomKey(value)
    }
    arr.push(item)
  }
  return arr;
}


export const getIconDocument = (key) => {
  const obj = {
    "csv": "csv", 
    "ppt": "ppt", 
    "doc": "doc", 
    "xls": "xls", 
    "pdf": "pdf", 
    "jpg": "image", 
    "file": "file",
    "csvx": "csv", 
    "pptx": "ppt", 
    "docx": "doc", 
    "xlsx": "xls", 
    "png": "image",
    "bemp": "image",
    "bmp": "image",
    "jpeg": "image",
  }
  return obj[key] || "file"
}

export const rundomAnim = () => {
  const arr = ["speed_one","speed_two","speed_three"];
  return arr[getRandomInt(arr.length)]
}


export const formatDate = (str) => {
  return str;
}

export const getCrfToken = () => {
  const crf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  return crf
}