import React, { Component } from "react";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

export class ModalWindows extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  constructor(props) {
    super(props);
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  render() {
    const { props, state } = this;
    
    return (
      <section style={{width:'100%'}} className={`fox_modal_window ${props.className}`} {...this.getElementConfig(props)}>
        {props.children}
      </section>
    );
  }
}
