const THEME_SILVER = `
  Hi kak Retna & Ulamulem team. 

  Saya mau dong informasi tentang Undangan online peket
  *SILVER*
  kalo bisa tolong sekalian kirimkan link contohnya

  Terima kasih banyak
  `;
const THEME_GOLD = `
  Hi kak Retna & Ulamulem team. 

  Saya mau dong informasi tentang Undangan online peket
  *GOLD*
  kalo bisa tolong sekalian kirimkan link contohnya
  dan apakah saya bisa request temanya?

  Terima kasih banyak
  `;
const THEME_PLATINUM = `
  Hi kak Retna & Ulamulem team. 

  Saya mau dong informasi tentang Undangan online peket
  *PLATINUM*
  kalo bisa tolong sekalian kirimkan link contohnya
  dan apakah saya bisa request temanya?
  
  Terima kasih banyak
  `;
const THEME_DIAMOND = `
  Hi kak Retna & Ulamulem team. 

  Saya mau dong informasi tentang Undangan online peket
  *DIAMOND*
  kalo bisa tolong sekalian kirimkan link contohnya
  dan apakah saya bisa request temanya?
  
  Terima kasih banyak
  `;
const THEME_CREATE = `
  Hi kak Retna & Ulamulem team. 

  Saya mau dong informasi tentang Undangan online, dan apakah saya bisa request temanya? boleh minta informasi detailnya?
  
  Terima kasih banyak
  `;

export const WhatsappTheme = (attr, ref) => {
  if(ref){
    const key = ref?.getAttribute?.("fox-message");
    // const message = 
    const message = {
      "diamond": THEME_DIAMOND,
      "gold": THEME_GOLD,
      "platinum": THEME_PLATINUM,
      "silver": THEME_SILVER,
      "buat-undangan": THEME_CREATE
    }
  
    if(message[key]){
      return message[key]
    }
  
    return `
    Hi kak Retna & Ulamulem team. 
  
    Apakah saya bisa request tema ini ${key} ? kalo boleh tahu berapa harganya yah
    
    Terima kasih banyak
    `
  }

  return ''
  
}