import * as types from "../reducers-constant";

export class StoragesSystem {
  setStorage = (data) => {
    const obj = {}
    if(Array.isArray(data)){
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        obj[item.type] = item.data || null
      }
    }else{
      obj[data.type] = data.data;
    }
    return async (dispatch) => {
      await dispatch({
        type: types.STORAGE_DATAS,
        ...obj
      });
    };
  };
}
