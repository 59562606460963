import * as types from "../reducers-constant";

const initialState = {
  language: "ind",
  user: {
    name: 'Hallow'
  },
  company: {},
  companies: [],
  companiesUpdate: new Date().getTime()
};

export const StoragesSystem = (state = initialState, action) => {
  switch (action.type) {
    case types.STORAGE_DATAS: {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};
