import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { GenerateAtribute } from "../../@utils/generate-atribute";

export class StyleEl extends Component {
  constructor(props) {
    super(props);
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  render() {
    const { props, state } = this;
    return (
     <>
      {/* <Helmet> */}
        <style {...this.getElementConfig(props)}>{props.media?.text}</style>
      {/* </Helmet> */}
     </>
    );
  }
}
